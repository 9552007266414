class Dropdown{
    constructor(mainNode) {
        let
            _dropdown = this,
            _opts = {
                action: 'click'
            },
            $container = $(mainNode),
            $trigger = $(mainNode).find('[data-dropdown-trigger]'),
            $content = $(mainNode).find('[data-dropdown-content]');
        _dropdown.state = {
            isClosed: true,
            isOpened: false
        };
        _dropdown.options = _opts;
        _dropdown.structure = {
            $container: $container,
            $trigger: $trigger,
            $content: $content
        };
        _dropdown.init();
        _dropdown.setHandlers();

    }

    init() {
        let _dropdown = this;
        let $container = _dropdown.structure.$container;

        $container.on(_dropdown.options.action, '[data-dropdown-trigger]', function (e) {
            e.preventDefault();
            if (_dropdown.state.isOpened) {
                _dropdown.close();
            } else {
                _dropdown.open();

                $('[data-dropdown]').not($container).removeClass('is-open');

                $(document).on('click.dropdown', function (e) {
                    if (!$(e.target).closest($container).length) {
                        _dropdown.close();
                        $(document).off('click.dropdown');
                    }

                });
            }
        });
    }

    open() {
        this.structure.$container.addClass('is-open');
        this.setState(1);
    }

    close() {
        this.structure.$container.removeClass('is-open');
        this.setState(0);
    }


    setState(state) {
        this.state.isClosed = !state;
        this.state.isOpened = state;
    }
    setHandlers(){
        let _dropdown = this;
        $(window).on('scroll',function(){
            if(_dropdown.state.isOpened){
                _dropdown.close();
            }
        })
    }
}


$('[data-dropdown]').each(function (i, el) {
    new Dropdown(el);
});